.container {
    background-color: black;
    display: flex;
}

.realEstateColumn {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 33vw;
}

.automotiveColumn {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 33vw;
}

.fitnessColumn {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 33vw;
}

.imgContainer {
    width: 85%;
    height: auto;
    margin: 2vw;
}

.img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.filler {
    height: 10vh;
}

@media screen and (max-width: 768px) {

    .filler {
        height: 3vh;
    }
    
}