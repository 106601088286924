@import url('https://fonts.cdnfonts.com/css/nimbus-sans-l');

.App {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Nimbus Sans L', sans-serif;
}

