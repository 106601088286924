.container {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.coverImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    filter: brightness(70%);
}

.titleTextContainer {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    background: rgba(0, 0, 0, 0.5);
}

h1, h3 {
    color: white;
    text-align: center;
    font-weight: bold;
    letter-spacing: 5px;
}

h1 {
    font-size: 4.5rem;
}

h3 {
    font-size: 2rem;
    margin-top: 1vh;
}

@media screen and (max-width: 768px) {
    .container {
        position: relative;
        width: 100vw;
        height: 30vh;
        /* height: 100vh; */
        overflow: hidden;
    }
    .coverImg {
        width: 100vw;
        /* height: 100%; */
        height: 30vh;
        /* object-fit: cover; */
        /* object-position: center; */
        /* position: absolute; */
        /* top: 50%; */
        /* left: 50%; */
        transform: translate(-50%, -50%);
        z-index: -1;
        filter: brightness(70%);
    }

    .titleTextContainer {
        width: 100vw;
        height: 30vh;
        /* height: 100vh; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 1;
        background: rgba(0, 0, 0, 0.5);
    }

    h1 {
        font-size: 2.5rem;
    }

    h3 {
        font-size: 1rem;
    }
    
}