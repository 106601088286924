.container {
    width: 100vw;
    height: 8vh;
    display: flex;
    background-color: black;
    justify-content: space-between;
    align-items: center;
}

.menu-button {
    margin-left: 20px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.line {
    width: 30px;
    height: 5px;
    background-color: #9E836E;
    margin: 3px;
}

.logo {
    flex: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

.logo-img {
    width: 60px;
}

.info-detail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: end;
    margin-right: 20px;
    color: #9E836E;
    font-weight: bold;
    font-size: 1.15em;
    flex: 1;
    justify-content: flex-end;
}

@media screen and (max-width: 768px) {
    .menu-button {
        margin-left: 10px;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
    
    .line {
        width: 25px;
        height: 4px;
        background-color: #9E836E;
        margin: 2.5px;
    }

    .info-detail {
        font-size: 0.6em;
    }

    .logo-img {
        width: 45px;
    }
    
}